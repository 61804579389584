html{
  overflow-x: hidden;
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue','Poppins'
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* overflow-x: hidden; */
  /* max-width: 100%; */

}

@media (max-width: 768px) {
  body {
    overflow-x: hidden;
  }
}


* {
  font-family: 'Poppins', sans-serif !important;
}

body, p, h1, h2, h3, h4, h5, h6, span, a {
  font-family: 'Poppins', sans-serif !important;
}

p,li {
  font-size: 20px;
  
}
.back-to-top {
  position: fixed;
  bottom: 30px;
  right: 30px;
  width: 50px;
  height: 50px;
  background-color: #333;
  color: white;
  font-size: 24px;
  border: none;
  border-radius: 50%;
  cursor: pointer;
  z-index: 1000;
  opacity: 0.7;
  transition: opacity 0.3s;
  display: block; /* Pastikan tombol ini tampil */
}

.back-to-top:hover {
  opacity: 1;
}

.back-to-top .tooltip {
  visibility: hidden;
  position: absolute;
  bottom: 60px;
  background-color: #555;
  color: #fff;
  text-align: center;
  padding: 5px;
  border-radius: 4px;
  font-size: 12px;
}

.back-to-top:hover .tooltip {
  visibility: visible;
}
.logo{
  width: 90%;
}

/* Media query responsif */
@media (max-width: 768px) {
  .back-to-top {
    width: 40px;
    height: 40px;
    font-size: 20px;
    bottom: 20px;
    right: 20px;
  }
  .logo{
    width: 75%;
  }
}

@media (max-width: 480px) {
  .back-to-top {
    width: 35px;
    height: 35px;
    font-size: 18px;
    bottom: 15px;
    right: 15px;
  }
}


/* NAVBAR */
.navbar-bg{
  background-color: white;
  margin-top: 5px;
}
.breaking-news-container {
  display: flex;
  align-items: center; /* Aligns items vertically in the middle */
  justify-content: flex-start; /* Aligns items to the left */
  background-color: #f3f3f3; /* Optional background color for container */
  
}
.breaking-news-container h1 {
  white-space: nowrap; /* Prevents text from wrapping */
  background: linear-gradient(-45deg, #BA1818, #EEEEEE, #BA1818, #333333);
  background-size: 200% 200%; /* Ensures background is large enough to animate */
  color: white; /* Text color for better contrast */
  padding: 10px 20px; /* Adds padding inside the h1 */
  margin: 0; /* Removes default margin */
  font-size: 18px; /* Optional font size adjustment */
  font-weight: bold; /* Optional font weight */
  text-transform: uppercase; 
  /* transform: skew(-15deg);  */
  /* -webkit-animation: Gradient 15s ease infinite;
  -moz-animation: Gradient 15s ease infinite;
  animation: Gradient 15s ease infinite; */
  z-index: 2;
}

@-webkit-keyframes Gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

@-moz-keyframes Gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

@keyframes Gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}


.breaking-news-container p {
  white-space: nowrap; /* Prevents text from wrapping */
  font-size: 14px; /* Optional font size adjustment */
  color: #333; /* Text color */
  animation: marquee 15s linear infinite;
  width: 100%;
}

/* Marquee effect for scrolling text */
@keyframes marquee {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(-100%);
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


/* HEADER */
.carousel-container {
  position: relative;
  width: 100%;
  height: 85vh;
}

.carousel-container img {
  width: 100%;
  height: 100%;
}

.carousel-item {
  position: relative;
  width: 100%;
  height: 85vh;
}

.carousel-item img {
  width: 100%;
  height: 100%;
  /* object-fit: cover; */
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(90deg, rgba(0, 0, 102, 0.8) 0%, rgba(0, 0, 102, 0.6) 50%, rgba(0, 0, 102, 0) 100%);
  display: flex;
  align-items: center;
}

.text-content {
  color: #fff;
  text-align: left;
  padding-left: 80px; /* Increased padding to move text to the right */
  max-width: 50%;
}

.sub-title {
  font-size: 18px;
  margin-bottom: 10px;
}

.title {
  font-size: 36px;
  font-weight: bold;
  margin-bottom: 20px;
}

.description {
  font-size: 16px;
  line-height: 1.5;
}

.left-button, .right-button {
  background: rgba(0, 0, 0, 0.5);
  color: white;
  border: none;
  position: absolute;
  top: 50%; /* Vertically center the buttons */
  transform: translateY(-50%); /* Adjust the vertical position */
  z-index: 1; /* Ensure the buttons appear above the carousel */
}

.left-button {
  left: 10px; /* Position it on the left side */
}

.right-button {
  right: 10px; /* Position it on the right side */
}
@media (max-width: 768px) {
  .carousel-container {
    height: 16vh; /* Optional: Reduce height on smaller screens */
  }
  .breaking-news-container p {
    width: 110vh;
  }
  .text-content {
    max-width: 100%; /* Make sure text uses full width */
    padding-left: 45px; /* Reduce padding for smaller screens */
    height: 85vh;
  }
  .text-content h1 {
    font-size: 10px; /* Reduced font size for h1 */
  }

  .text-content h5 {
    font-size: 10px; /* Reduced font size for h5 */
  }

  .text-content p {
    font-size: 8px; /* Reduced font size for paragraph text */
  }
  .carousel-item img {
    height: auto; /* Ensure images scale down properly */
  }
}

/* @keyframes marquee {
  0% { transform: translateX(100%); }
  100% { transform: translateX(-100%); }
} */

/* About */
#about {
  padding: 100px 0;
}
.fade-in-right {
  opacity: 0;
  transform: translateX(100px); /* Initially off-screen to the right */
  transition: opacity 0.6s ease-out, transform 0.6s ease-out;
}

.fade-in-right.visible {
  opacity: 1;
  transform: translateX(0); /* Moves the element to its original position */
}

/* Fade-in from the left */
.fade-in-left {
  opacity: 0;
  transform: translateX(-100px); /* Initially off-screen to the left */
  transition: opacity 0.6s ease-out, transform 0.6s ease-out;
}

.fade-in-left.visible {
  opacity: 1;
  transform: translateX(0); /* Moves the element to its original position */
}
.bg-img{
  background-image: url('../public/img/vektor.jpg');
  margin-top: 10px;
}
.mt-about{
  margin-top: 50px;
}
.mt-100{
  margin-top: 100px;
}
.mt-text{
  margin-top: 150px;
}
.fixed-top {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000; /* Ensure it appears above other content */
}

.advertisment{
  width: 1170px !important;
  height: auto;
}
.counters-section {
  position: relative;
  text-align: center;
  margin-top: 50px;
  background-image: url('../public/img/img-3.svg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover; /* This ensures the image covers the full width */
  width: 100vw; /* Make the section full viewport width */
  height: 300px; /* Adjust height as needed */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-left: calc(-50vw + 50%); 
}

.counters-section h2 {
  color: white;
  padding-top: 20px;
  font-size: 30px !important;
  z-index: 1;
}

.counters-content {
  display: flex;
  justify-content: space-around;
  width: 100%;
  max-width: 1000px;
  padding: 0 20px;
  z-index: 1;
}

.counter-item {
  text-align: center;
  margin: 0 15px;
}

.counter-item h3 {
  color: white;
  font-size: 60px !important;
  margin: 0;
}

.counter-item p {
  color: white;
  font-size: 20px !important;
  margin: 5px 0 0;
}

@media (max-width: 768px) {
  .counters-section {
    height: auto; /* Allow height to adjust with content on smaller screens */
    padding: 20px 0;
  }

  .counters-content {
    flex-direction: column;
    align-items: center;
  }

  .counter-item {
    margin-bottom: 20px;
  }

  .counter-item h3 {
    font-size: 2rem;
  }

  .counter-item p {
    font-size: 0.9rem;
  }
}

@media (min-width: 769px) {
  .counter-item h3 {
    font-size: 3rem;
  }
}


@media (max-width: 768px) {
  .mt-100{
    margin-top: 0px;
  }
  .about-section {
    display: flex;
    flex-direction: column-reverse; /* Reversing the column order */
  }

  .advertisment {
    width: 100% !important;
    height: auto;
  }

  .about-text {
    text-align: center;
  }
}
/* Tambahkan style untuk memastikan gambar mengisi seluruh area */

/* team */

#team {
  padding: 100px 0;
}
.container {
  position: relative;
}

.slick-round {
  position: absolute;
  bottom: 230px;
  display: block;
  width: 100%;
  padding: 0;
  margin: 0;
  list-style: none;
  text-align: center;
}

.slider-btn {
  position: absolute;
  top: 35%;
  transform: translateY(-50%);
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  border: none;
  font-size: 2.5rem;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  cursor: pointer;
  z-index: 10;
}

.prev-btn {
  left: 10px;
}

.next-btn {
  right: 10px;
}

.team {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0 20px; /* Adjust spacing between images */
}

.team .thumbnail {
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.team-ly {
  height: 45%;
}

.team-name {
  margin-top: 10px;
  font-weight: bold;
}

.row-about {
  display: flex;
  justify-content: center;
  overflow: hidden;
  white-space: nowrap;
  transition: transform 0.5s ease-in-out;
  cursor: grab; /* Kursor berubah menjadi tangan saat drag */
}

.row:active {
  cursor: grabbing;
}
.additional-images {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin-top: 20px;
}

.ad-img {
  height: auto;
  margin-bottom: 20px; /* Space between ads */
}

.ad-img-1 {
  /* Adjust these values as needed to match your design */
  width: auto;
  max-width: 80%; /* Prevents the image from exceeding container width */
}

.ad-img-2 {
  width: 100%;
  max-width: 100%;
  margin-top: 50px;
}
.members-title {
  font-size: 2.5rem; /* Atur ukuran sesuai preferensi */
  font-weight: bold;
  color: #333; /* Sesuaikan warna sesuai desain */
  position: relative;
  margin-bottom: 40px;
  margin-left: -28%;
  display: inline-block;
}
.members-title::before {
  content: "";
  position: absolute;
  left: -285px; /* Jarak garis dari teks */
  top: 50%;
  transform: translateY(-50%);
  width: 260px; /* Panjang garis */
  height: 5px; /* Ketebalan garis */
  background-color: #d04a36; /* Warna garis sesuai gambar */
}
.title {
  font-size: 3.5rem;
  font-weight: bold;
  color: #333;
  text-align: center;
  margin-bottom: 2rem;
  position: relative;
}

.title::after {
  content: '';
  display: block;
  width: 100px;
  height: 3px;
  background-color: #e63946;
  position: absolute;
  bottom: -10px;
  left: 50%;
  transform: translateX(-50%);
}

.company-list {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.company-card {
  display: flex;
  align-items: stretch;
  background-color: #ffffff;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1),
              0 10px 20px rgba(0, 0, 0, 0.05);
  transition: all 0.3s ease;
  transform: perspective(1000px) rotateX(0deg) rotateY(0deg) scale(1);
}

.company-card:hover {
  transform: perspective(1000px) rotateX(2deg) rotateY(2deg) scale(1.02);
  box-shadow: 0 8px 12px rgba(0, 0, 0, 0.15),
              0 15px 30px rgba(0, 0, 0, 0.1);
}

.company-card.odd {
  flex-direction: row-reverse;
}
.company-logo {
  width: 30%;
  padding: 20px;
  text-align: center;
  /* background-color: #a8dadc; */
}

.company-logo img {
  width: 120px;
  height: 120px;
  object-fit: contain;
  margin-bottom: 10px;
}

.company-logo h2 {
  font-size: 1.2rem;
  color: #1d3557;
  margin: 0;
}

.company-details {
  width: 70%;
  padding: 20px;
  display: flex;
  flex-direction: column;
}

.company-details p {
  margin: 5px 0;
  color: #333;
  font-size: 15px; /* Specify font-size with px */
  line-height: 1.6;
  display: flex; /* Use flexbox to align items */
  /*align-items: center;  /Center align items vertically */
  text-align: left; /* Align text to the left */
  white-space: normal; /* Allow text wrapping */
}

.company-details .icon {
  color: #4CAF50; /* Icon color */
  margin-right: 10px; /* Space between icon and text */
  flex-shrink: 0; /* Prevent icon from shrinking */
}

.company-details a {
  color: #333;
  text-decoration: none;
}

.company-details a:hover {
  text-decoration: underline;
  color: #4CAF50;
}
.company-details h1 {
  font-size: 1.5rem; /* Adjust size as necessary */
  color: #1d3557;    /* Change text color if needed */
  margin: 0 0 10px;  /* Add margin below the name */
  position: relative; /* Positioning for the underline effect */
}

.company-details h1::after {
  content: '';
  display: block;
  width: 100%;
  height: 2px; /* Height of the underline */
  background-color: #e63946; /* Underline color */
  position: absolute;
  bottom: -5px; /* Adjust distance from the text */
  left: 0;
}

@media (max-width: 768px) {
  .company-card, .company-card.odd {
    flex-direction: column;
  }

  .company-logo, .company-details {
    width: 100%;
  }
  #team {
    padding: 0px 0;
  }
}
@media (max-width: 768px) {
  .slick-dots li {
    margin: 0 3px !important; /* Adjust spacing for mobile */
  }
  
  .slick-dots li button:before {
    font-size: 8px  !important; /* Even smaller dots on mobile */
  }
  .team-ly {
    height: 20%;
  }
  .team-img {
    width: 120px;
    height: 120px;
  }
  .ad-img {
    max-width: 90%;
  }
  .additional-images {
    flex-direction: column;
    align-items: center;
  }
  .slider-btn {
    top: 20%;
    font-size: 2rem;
    width: 40px;
    height: 40px;
  }

  .team-name {
    font-size: 8px;
  }
}


/* CONTACT */

/* Contact Form Styles */

/* Main container with equal columns */
#kontak {
  padding: 100px 0;
}
.contact-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 3rem;
  font-family: 'Arial', sans-serif;
  color: #5f4b8b;
}
.contact-title {
  font-size: 2.5rem; /* Atur ukuran sesuai preferensi */
  font-weight: bold;
  color: #333; /* Sesuaikan warna sesuai desain */
  position: relative;
  margin-bottom: 40px;
  margin-left: 20%;
  display: inline-block;
}
.contact-title::before {
  content: "";
  position: absolute;
  left: -285px; /* Jarak garis dari teks */
  top: 50%;
  transform: translateY(-50%);
  width: 260px; /* Panjang garis */
  height: 5px; /* Ketebalan garis */
  background-color: #d04a36; /* Warna garis sesuai gambar */
}
.form-section {
  width: 80%;
  margin: 0 auto; /* Keep it centered */
  padding: 1.5rem;
  transform: translateX(10%); /* Slightly move it to the right */
}
.form-section h2 {
  font-size: 2rem;
  margin-bottom: 0.5rem;
  color: #7b4caa;
}

.form-section p {
  margin-bottom: 2rem;
  font-size: 1.2rem;
  color: #6a6a6a;
}

.contact-form {
  display: flex;
  flex-direction: column;
}

.contact-form label {
  font-weight: bold;
  margin-bottom: 0.5rem;
  color: #5f4b8b;
}

.contact-form input, 
.contact-form textarea {
  width: 100%;
  padding: 1rem;
  margin-bottom: 1.5rem;
  border: 1px solid #dcdcdc;
  border-radius: 8px;
  font-size: 1rem;
}

.contact-form button {
  background-color: #a679cf;
  color: white;
  padding: 1rem;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  font-size: 1rem;
  width: 100%;
}

.contact-form button:hover {
  background-color: #8b5fbd;
}

.contact-info {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

.contact-image img {
  max-width: 100%;
  height: auto;
}

.contact-details {
  margin-top: 2rem;
  color: #6a6a6a;
  font-size: 0.9rem;
}

.contact-details a {
  color: inherit;
  text-decoration: none;
}

.contact-details a:hover {
  text-decoration: underline;
}

/* Padding and margins to ensure the contact details match the design */
.contact-details p {
  margin-bottom: 1rem;
}

.leaflet-container {
  width: 100%;
  height: 400px; /* Atur ukuran sesuai kebutuhan */
  padding: 20px 20px;
  margin-top: 40px;
  background: none !important;
  margin-bottom: 40px;
}
/* Responsive design */
@media (max-width: 768px) {
  .form-section {
    width: 100%; /* Full width on smaller screens */
    padding: 1rem;
    transform: none; /* Reset any translations for mobile */
    margin: 0; /* No extra margins for smaller screens */
  }
  .contact-container {
    grid-template-columns: 1fr;
    padding: 2rem;
  }

  .form-section,
  .contact-info {
    margin-bottom: 2rem;
  }

  .contact-form button {
    width: 100%;
  }
}



/* GALLERY */
/* Fade-in animation */
#gallery {
  padding: 100px 0;
}
.fade-in {
  opacity: 0;
  transform: translateY(20px);
  transition: opacity 0.6s ease-out, transform 0.6s ease-out;
}

.fade-in.visible {
  opacity: 1;
  transform: translateY(0);
}

.gallery-container {
  max-width: 1200px;
  margin: 0 auto;
}

.gallery-title {
  font-size: 2.5rem; /* Atur ukuran sesuai preferensi */
  font-weight: bold;
  color: #333; /* Sesuaikan warna sesuai desain */
  position: relative;
  margin-bottom: 40px;
  margin-left: 25%;
  display: inline-block;
}
.gallery-title::before {
  content: "";
  position: absolute;
  left: -285px; /* Jarak garis dari teks */
  top: 50%;
  transform: translateY(-50%);
  width: 260px; /* Panjang garis */
  height: 5px; /* Ketebalan garis */
  background-color: #d04a36; /* Warna garis sesuai gambar */
}

.gallery-grid {
  display: grid;
  gap: 10px;
  margin-bottom: 30px;
}

.gallery-item {
  overflow: hidden;
  border-radius: 8px;
}

.gallery-item img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

/* Labuan Bajo grid */
.labuan-bajo {
  grid-template-columns: 1fr 1fr;
}

.labuan-bajo .item1 {
  grid-column: 1;
  grid-row: 1;
}

.labuan-bajo .item2 {
  grid-column: 1;
  grid-row: 2;
}

.labuan-bajo .item3 {
  grid-column: 2;
  grid-row: 1 / span 2;
}

.labuan-bajo .item4 {
  grid-column: 1 / span 2;
  grid-row: 3;
}

.labuan-bajo .item5 {
  grid-column: 1;
  grid-row: 4;
}

.labuan-bajo .item6 {
  grid-column: 2;
  grid-row: 4;
}

/* Video container styles */
.video-container {
  margin-bottom: 30px;
}

.video-container video {
  width: 100%;
  max-width: 100%;
  height: auto;
  border-radius: 8px;
}

/* Pelantikan Pengurus Masa Kahar grid */
.ppmk {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto auto auto;
  gap: 10px;
}

.ppmk .ppmk1 {
  grid-column: 1;
  grid-row: 1;
}

.ppmk .ppmk2 {
  grid-column: 2;
  grid-row: 1;
}

.ppmk .ppmk3 {
  grid-column: 1;
  grid-row: 2;
}

.ppmk .ppmk4 {
  grid-column: 2;
  grid-row: 2;
}

.ppmk .ppmk5 {
  grid-column: 1 / span 2;
  grid-row: 3;
}

@media (max-width: 768px) {
  .labuan-bajo, .ppmk {
    grid-template-columns: 1fr;
  }
  
  .labuan-bajo .item1, .labuan-bajo .item2, .labuan-bajo .item3, 
  .labuan-bajo .item4, .labuan-bajo .item5, .labuan-bajo .item6,
  .ppmk .ppmk1, .ppmk .ppmk2, .ppmk .ppmk3, .ppmk .ppmk4, .ppmk .ppmk5 {
    grid-column: 1;
    grid-row: auto;
  }
  .gallery-title {
    font-size: 2rem; /* Ubah ukuran font untuk layar lebih kecil */
  }

  .gallery-title::before {
    left: -115px; /* Lebih dekat dengan teks pada layar kecil */
    width: 95px; /* Pendekkan garis */
  }
}
@media (max-width: 480px) {
  .gallery-title {
    font-size: 1.5rem; /* Ukuran font lebih kecil untuk ponsel */
  }

  .gallery-title::before {
    left: -85px; /* Lebih dekat dengan teks pada layar kecil */
    width: 70px; /* Pendekkan lagi garis untuk layar kecil */
  }
}
/* FOOTER */

/* Footer styles */
.footer {
  background-color: #2c3e50;
  color: #ffffff;
  padding: 40px 0;
  font-family: Arial, sans-serif;
}

.footer-container {
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 0 20px;
}

.footer-section {
  width: 100%;
  margin-bottom: 30px;
  margin-left: 21px;
}

.footer-section h3 {
  color: #2ecc71;
  font-size: 18px;
  margin-bottom: 15px;
  position: relative;
  padding-bottom: 10px;
}

.footer-section h3::after {
  content: '';
  position: absolute;
  left: 0;
  bottom: 0;
  width: 30px;
  height: 2px;
  background-color: #2ecc71;
}

.footer-section p, .footer-section li {
  font-size: 14px;
  line-height: 1.5;
}

.footer-section ul {
  list-style-type: none;
  padding: 0;
}

.footer-section li {
  margin-bottom: 10px;
}

.footer-section a {
  color: #ffffff;
  text-decoration: none;
}

.footer-section p {
  color: #ffffff;
  text-decoration: none;
}

.footer-section a:hover {
  color: #2ecc71;
}

.social-icons {
  display: flex;
  gap: 15px;
}

.social-icons a {
  font-size: 18px;
  color: #ffffff;
  text-decoration: none;
}

.copyright {
  width: 100%;
  text-align: left;
  font-size: 12px;
  border-top: 1px solid rgba(255, 255, 255, 0.1);
  padding-top: 20px;
  margin-top: 30px;
}

.copyright p {
  color: #ffffff;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
}

/* Responsive design */
@media (min-width: 768px) {
  .footer-section {
    width: calc(25% - 20px);
    margin-bottom: 0;
  }
  
  .footer-section:first-child {
    width: calc(30% - 20px);
  }
  
  .footer-section:nth-child(2),
  .footer-section:nth-child(3) {
    width: calc(20% - 20px);
  }
  
  .footer-section:last-child {
    width: calc(30% - 20px);
  }
}

